:root {
  --dec-font: Georgia, 'Times New Roman', Times, serif;
  --body-font: Arial, Helvetica, sans-serif;
  --altum-bg: #732C2C; // background for nav and footer
  --nav-text-primary: rgba(253, 253, 253, 1); // hex code #fdfdfd
  --nav-text-primary-faded: rgba(253, 253, 253, 0.5);
  --nav-text-secondary: #ABA361;
  --relief-bg: #F7F8FB;
  --main-bg: rgba(253, 253, 253, 1);
  --main-text-secondary: rgba(108, 117, 125, 1)
}



body {
  background-color: #F7F8FB;
  font-family: Georgia, 'Times New Roman', Times, serif;
  justify-content: space-between;
}

footer{
  padding: 16px;
  font-family: var(--body-font);
  background-color: var(--altum-bg);
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  justify-content: space-between;

  #about-container {
    margin-bottom: 16px;
  }

  .toe {
    color: var(--nav-text-primary);

    .icon-container {
      display: flex;
      align-items: center;
    }

    .icon-wrapper {
      margin-right: 12px;
    }

    .png-wrapper {
      border-radius: 50%;
      transition: box-shadow 0.3s ease;
    }

    .png-wrapper:hover {
      border-radius: 50%;
      box-shadow: 0px 0px 0 4px var(--nav-text-secondary);
    }

    .toe-img {
      fill: var(--nav-text-primary);
      transition: fill 0.3s ease;
    }

    .toe-img:hover {
      fill: var(--nav-text-secondary)
    }

    .toe-link {
      color: var(--nav-text-primary);
      transition: fill 0.3s ease;
    }

    .toe-link:hover {
      color: var(--nav-text-secondary);
    }
  }
}

.link-dark:hover {
  color: var(--main-text-secondary)!important;
}

.navbar {
  font-family: var(--body-font);
  background-color: var(--altum-bg);

  .navbar-toggler, .dropdown-menu {
    border: var(--nav-text-primary-faded) 0.5px solid;
  }

  .navbar-toggler:active, .navbar-toggler:focus {
    border: var(--nav-text-secondary) 0.5px solid;
  }

  a, button {
    color: var(--nav-text-primary) !important;
  }

  a:hover, a:active, a:focus,
  button:hover, button:active, button:focus {
    color: var(--nav-text-secondary) !important;
  }

  .dropdown-menu, .dropdown-toggle, .dropdown-item:hover {
    font-family:Arial, Helvetica, sans-serif;
    background-color: var(--altum-bg);
  }

  .dropdown-toggle {
    background-color: #732C2C;
    padding-left: 0;
    padding-right: 0;
  }
  
  .dropdown-link {
    text-decoration: none;
  }

  .btn {
    border: none;
    height: 40px;
  }

}

#nonfooter {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.secondary-text {
  color: var(--main-text-secondary)!important;
}

.main-el {
  flex-grow: 1;
  display: flex;
}

.outer-container {
  /* min-height: 100vh; */
  background-color: #FDFDFD;
  flex-grow: 1;
}

.toolbar {
  width: -moz-fit-content;
  width: fit-content;
  border-top: 1px rgba(211, 211, 211, 1) solid;
  border-bottom: 1px rgba(211, 211, 211, 1) solid;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 4px;
}

ol {
  margin: 0;
}

.scroll {
  position: relative;
}

.scroll, .item-header, #hexapla, #reader-body, .form-container, .form-container > form, .inner-container{
  margin: 0 auto;
  max-width: 380px;
}

.form-check-input {
  border: 1px solid black;
}

.toolbar-btn {
  margin: auto 0;
  padding: 0;
}

#reader-body {
  white-space: pre-wrap;
  position: relative;
}

.faded {
  opacity: 50%;
}

.infobox {
  padding: 12px;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fdfdfd;
  font-size: smaller;
}

.highlighted {
  background: rgba(211, 211, 211, 1);
}

.action-heading {
  display: flex;
  justify-content: space-between;
}

.line {
  text-align: justify;
  hyphens: auto;
}

.word-span {
  cursor: pointer;
}

.preview-container {
  padding: 12px;
  margin: 5px 0;
  border: 1px rgba(211, 211, 211, 1) solid;
}

.preview-container:hover {
  background-color: rgba(239, 247, 207, .5);
}

.scroll-preview {
  font-size: smaller;
}

.nav-btn {
  height: 40px;
}

.entry-container .dictionary-bar {
  border-bottom: 1px rgba(239, 239, 240, 1) solid;
  border-bottom: 1px rgba(211, 211, 211, 1) solid;
}

.entry-container {
  h6 {
    margin: 0;
  }
}

.dictionary-bar {
  width: fit-content;
  border-top: 1px rgba(211, 211, 211, 1) solid;
  border-bottom: 1px rgba(211, 211, 211, 1) solid;
}

.lang-bar {
  width: fit-content;
  border-bottom: 1px rgba(211, 211, 211, 1) solid;
}

.flashcard-mat {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flashcard {
  padding: 24px;
  border: dotted black 0.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 276px;
  height: 220px;
}

.recto {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 576px) {
  .flashcard {
    width: 380px;
    height: 220px;
  }

  .dropdown {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }


  footer {
    flex-direction: row;
    justify-content: space-between;

    #about-container {
      margin-bottom: 0;

      .icon-wrapper {
        margin-left: 12px;
        margin-right: 0;
      }
    }
  }
}

@media (min-width: 796px) {
  .form-container, .outer-container {
    max-width: 796px;
    /* padding: 0 auto; */
    margin: 0 auto;
    padding: 0 12px;
  }

  .item-header {
    max-width: 768px;
  }

  #reader-body, .scroll, .toolbar, .form-container > form, .inner-container  {
    margin: 0;
  }

  .word-span-wrapper {
    position: relative;
  }

  .infobox {
    position: absolute;
    width: 380px;
    margin: 0 12px;
    border: 1px rgba(211, 211, 211, 1) solid;
  }
}

@media (min-width: 1024px) {
  .App {
    margin: auto;
  }
}

@media (min-width: 1200px) {

}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.error-message {
  font-style: italic;
}